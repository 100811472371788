
import { getPolicies, Policy } from '@/api/policies';
import {
  UserConsents,
  UserConsentStatus,
  UserConsentStatuses,
} from '@/api/users';
import { AsyncValue, useAsync } from '@/composables/async';
import i18n from '@/lang';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { computed, Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface UserConsentCheckbox {
  policy: Policy;
  isConsented: boolean;
}

async function getPoliciesAndBuildCheckboxes(
  policyRegion: string,
  locale: string,
  userConsents: UserConsents
): Promise<UserConsentCheckbox[]> {
  const policies = await getPolicies({
    filters: [
      {
        name: 'policyRegionCode',
        operator: FilterOperator.EQUAL,
        value: [policyRegion],
      },
      {
        name: 'i18nCode',
        operator: FilterOperator.EQUAL,
        value: [locale],
      },
    ],
  });
  return Object.values(policies).map(
    (policy): UserConsentCheckbox => ({
      policy,
      isConsented:
        userConsents[policy.code]?.consentStatus ===
        UserConsentStatus.CONSENTED,
    })
  );
}

@Component({
  name: 'TermsAndConditionsDialog',
})
export default class extends Vue {
  @Prop({ default: 'min-content' }) width!: string;
  @Prop() userConsents!: UserConsents;

  checkboxes!: Ref<AsyncValue<UserConsentCheckbox[]>>;

  created() {
    this.checkboxes = useAsync(
      computed(() =>
        getPoliciesAndBuildCheckboxes(
          UserModule.policyRegion,
          i18n.locale,
          this.userConsents
        )
      )
    );
  }

  handleCancelDialog() {
    this.$emit('cancel');
  }

  handleAgree() {
    const checkboxes = unref(this.checkboxes).data;
    if (!checkboxes) {
      return;
    }
    const newUserConsents: UserConsentStatuses = Object.fromEntries(
      checkboxes.map((checkbox) => [
        checkbox.policy.code,
        checkbox.isConsented
          ? UserConsentStatus.CONSENTED
          : UserConsentStatus.NOT_CONSENTED,
      ])
    );
    this.$emit('agree', newUserConsents);
  }
}
