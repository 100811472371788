import Vue, { DirectiveOptions } from 'vue';

import ElementUI from 'element-ui';
import SvgIcon from 'vue-svgicon';

import 'normalize.css';
import '@/assets/font/font.scss';
import '@/styles/index.scss';
// import '@/styles/theme_index.scss'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import '@/styles/tree-select.scss'

import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import i18n from '@/lang';
import '@/icons/components';
import '@/router/router-listener';
import * as directives from '@/directives';
import * as filters from '@/utils/misc';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import { UserModule } from '@/store/modules/user';
import { getEnvConfig } from '@/api/config';
import { initializeService } from '@/utils/request';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import htmlToPdf from '@/utils/htmlToPdf';

Vue.use(htmlToPdf);

/*======================================================================================*/
Vue.use(ElementUI, {
  size: 'small',
  i18n: (key: string, value: string) => i18n.t(key, value),
});

/*======================================================================================*/
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
});

/*======================================================================================*/
// Register global directives
Object.keys(directives).forEach((key) => {
  // console.log(directives)
  // console.log((directives as  { [key: string]: DirectiveOptions})[key])
  // console.log("-------------------------------------");
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key]);
});

/*======================================================================================*/
// Register global filter functions
Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string]: Function })[key]);
});

/*======================================================================================*/
Vue.config.productionTip = false;

/*======================================================================================*/
// Integrate with KeyCloak
let initKeycloakConfig = (data: any) => {
  const config: Keycloak.KeycloakConfig = {
    url: data.VUE_APP_IDENTITY_SERVICE_URL,
    realm: data.VUE_APP_IDENTITY_SERVICE_REALM as string,
    clientId: data.VUE_APP_IDENTITY_SERVICE_CLIENT_ID as string,
  };
  return config;
};
let keycloak: KeycloakInstance;

getEnvConfig()
  .then((res: any) => {
    Vue.prototype.$envConfig = res.data;
    keycloak = new Keycloak(initKeycloakConfig(res.data));
    initializeService(res.data.VUE_APP_BASE_API);
    return keycloak.init({
      onLoad: res.data
        .VUE_APP_IDENTITY_SERVICE_ON_LOAD as Keycloak.KeycloakOnLoad,
    });
  })
  .then((auth: any) => {
    if (!auth) {
      console.log('Not authenticated');
      // Disable if browser auto-refresh always when not authenticated
      window.location.reload();
      return;
    } else {
      Vue.prototype.$keycloak = keycloak;
      UserModule.keycloakLogin(keycloak.token as string);

      new Vue({
        router,
        store,
        i18n,
        render: (h: any) => h(App),
      }).$mount('#app');
    }

    setInterval(() => {
      keycloak
        .updateToken(
          parseInt(
            Vue.prototype.$envConfig
              .VUE_APP_IDENTITY_SERVICE_REFRESH_TOKEN_TIME!
          )
        )
        .then((refreshed) => {
          if (refreshed) {
            console.log('Token refreshed');
            // save token
            UserModule.saveToken(keycloak.token as string);
          } else {
            console.log(
              'Token not refreshed, valid for ' +
                Math.round(
                  (keycloak.tokenParsed!.exp as number) +
                    keycloak.timeSkew! -
                    new Date().getTime() / 1000
                ) +
                ' seconds'
            );
          }
        })
        .catch((error) => {
          console.log('Failed to refresh token', error);
        });
    }, 60000);
  })
  .catch((error) => {
    console.log('Authenticated Failed', error);
  });
