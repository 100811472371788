import axios, { AxiosPromise } from 'axios';

const request = axios.create({
  baseURL: '',
});
export function getEnvConfig(): AxiosPromise {
  return request({
    url: `/config.json`,
    method: 'get',
  });
}
