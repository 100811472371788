import router from '.';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Message } from 'element-ui';
import { Route } from 'vue-router';
import { UserModule } from '@/store/modules/user';
import {
  PermissionModule,
  PermissionsConfig,
} from '@/store/modules/permission';
import i18n from '@/lang'; // Internationalization
import settings from '../settings';
import { switchTheme } from '@/utils';
import { THEME_LIST } from '@/utils/workData/lookuptable';
import { PageModule } from '@/store/modules/page';

NProgress.configure({ showSpinner: false });

const whiteList = ['/auth-redirect', '/forget-pwd', '/reset-pwd'];

const getPageTitle = (key: string) => {
  const hasKey = i18n.te(key);
  if (hasKey) {
    const pageName = i18n.t(key);
    return `${pageName} - ${settings.title}`;
  }
  return settings.title;
};

router.beforeEach(async (to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start();

  // Determine whether the user has logged in
  if (UserModule.token) {
    // Load user's info if not loaded yet
    if (!UserModule.organizationId) {
      try {
        await UserModule.GetUserInfo();

        /**
         * Temporary solution to check home claims for entry point in the app,
         * Automattically log out if there are no claims to show the landing route
         */
        if (
          !UserModule.claims.hasClaim('AUTHRSC_MOD_HOME') &&
          !UserModule.claims.hasClaim('AUTHRSC_PAGE_HOME')
        ) {
          /** Go to route component to show message */
          next();

          /** Wait 3s to read the message: no rights to view the page for better UX */
          await new Promise((resolve) => setTimeout(resolve, 3000));

          /** Automatically log out aftear reading the message */
          UserModule.keycloakLogout();

          return;
        }

        const themes = THEME_LIST;
        themes.forEach((item: any) => {
          if (item.id === UserModule.theme) {
            switchTheme(
              'body-theme-' + item.key,
              item.colorVlue,
              item.hoverColor
            );
          }
        });

        // Dynamically add accessible routes
        // Generate accessible routes map based on role
        await PermissionModule.GenerateRoutes({
          companyType: UserModule.companyType,
          roles: UserModule.roles,
          claims: UserModule.claims,
          menus: UserModule.menus,
          subscriptions: UserModule.subscriptions,
        });
        router.addRoutes(PermissionModule.dynamicRoutes);

        // Hack: ensure addRoutes is complete
        // Set the replace: true, so the navigation will not leave a history record
        next({ ...to, replace: true });
      } catch (err) {
        // Remove token and redirect to login page
        UserModule.ResetToken();
        // Message.error(err as any)
        next(`/home?redirect=${to.path}`);
        NProgress.done();
      }
    } else {
      next();
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      next();
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/home?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach((to: Route) => {
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done();

  const titleKey = to.meta?.title;
  if (titleKey) {
    document.title = getPageTitle(titleKey);
    PageModule.setTitle(i18n.te(titleKey) ? i18n.t(titleKey) : []);
  }
});
