/* tslint:disable */
import './404';
import './administration';
import './assetMgmt';
import './assets';
import './chart';
import './custMgmt';
import './dashboard';
import './dashboardConf';
import './deactive';
import './edit';
import './education';
import './email';
import './exit-fullscreen';
import './events';
import './export';
import './fullscreen';
import './guide';
import './hamburger';
import './home';
import './international';
import './kpiZoom';
import './lang';
import './language';
import './link';
import './maintConf';
import './maintenance';
import './prodCata';
import './report';
import './save';
import './setting';
import './subscriptionPackages';
import './subsMgmt';
import './theme';
import './tree';
import './user';
import './userRoleMgmt';
import './alarmConfiguration';
import './trips';
import './deployManagement';
import './health';
